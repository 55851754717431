
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEqual, pick } from "lodash";

import {
  useDataTable,
  DataTable,
  AdvancedSearch,
  FormFieldMultiSelect,
  OptionType,
  getFilterParamAsStringArray,
  getFilterParams,
  FilterTagType,
  EditIcon,
  BaseButton,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResource } from "@tager/admin-services";

import { formatMoney } from "@/utils/common";

import { statusOptions } from "../constants";
import { getCarsAmp } from "../requests";
import { getAmpCarsUpdateUrl } from "../routes";
import { AmpCarInterface } from "../typings";

import { COLUMN_DEFS, SORT_OPTIONS } from "./AmpCarsList.helpers";

export default defineComponent({
  name: "AmpCarList",
  components: {
    BaseButton,
    EditIcon,
    Page,
    DataTable,
    AdvancedSearch,
    FormFieldMultiSelect,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      fetchEntityList: fetchDataList,
      isLoading: isDataLoading,
      rowData: carsData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      sort,
    } = useDataTable<AmpCarInterface>({
      fetchEntityList: (params) =>
        getCarsAmp({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          sort: params.sort || "",
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Список бу авто",
      defaultSort: SORT_OPTIONS[0].value,
    });

    const initialStatusFilter = computed(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "status");
      return statusOptions.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const statusFilter = ref<Array<OptionType>>(initialStatusFilter.value);
    watch(initialStatusFilter, () => {
      statusFilter.value = initialStatusFilter.value;
    });

    const filterParams = computed(() => {
      return getFilterParams({
        status: statusFilter.value.map((item) => item.value),
      });
    });

    watch(filterParams, () => {
      const newQuery = {
        ...pick(route.query, ["query", "pageNumber"]),
        ...filterParams.value,
      };

      if (!isEqual(route.query, newQuery)) {
        router.replace({ query: newQuery });
        fetchDataList();
      }
    });

    function handleTagRemove(event: FilterTagType) {
      if (event.name === "status") {
        statusFilter.value = statusFilter.value.filter(
          (item) => item.value !== event.value
        );
      }
    }

    const tags = computed<Array<FilterTagType>>(() => [
      ...statusFilter.value.map((item) => ({
        value: item.value,
        label: item.label,
        name: "status",
        title: "Статус",
      })),
    ]);

    const isRowDataLoading = computed<boolean>(() => isDataLoading.value);

    return {
      columnDefs: COLUMN_DEFS,
      rowData: carsData,
      isRowDataLoading: isRowDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      formatMoney,

      tags,
      handleTagRemove,
      statusFilterOptions: statusOptions,

      statusFilter,

      sortOptions: SORT_OPTIONS,
      sortValue: sort,
      getAmpCarsUpdateUrl,
    };
  },
});
