
import { computed, defineComponent } from "vue";

import { HomeIcon, SettingsIcon, ViewListIcon } from "@tager/admin-ui";
import { MenuItemType, PageLayout } from "@tager/admin-layout";
import { useI18n } from "@tager/admin-services";

import { getLinks } from "@/constants/links";

export default defineComponent({
  name: "BaseApp",
  components: { PageLayout },
  setup() {
    const links = computed(() => getLinks());

    const sidebarMenuList: Array<MenuItemType> = [
      { id: "home", icon: HomeIcon, ...links.value.HOME },
      { id: "new", icon: ViewListIcon, ...links.value.CARS_NEW },
      { id: "amp", icon: ViewListIcon, ...links.value.CARS_AMP },
      {
        id: "amp_partners",
        icon: ViewListIcon,
        ...links.value.CARS_PARTNERS,
      },
      { id: "sync", icon: ViewListIcon, ...links.value.CARS_SYNC_SESSIONS },
      { id: "gifts", icon: ViewListIcon, ...links.value.GIFTS },
      { id: "colors", icon: ViewListIcon, ...links.value.COLORS },
      { id: "settings", icon: ViewListIcon, ...links.value.SETTINGS_COMMON },
      {
        id: "commands",
        icon: SettingsIcon,
        text: "Команды",
        children: [
          links.value.CRON_COMMANDS,
          links.value.CRON_COMMANDS_LOGS,
          links.value.CRON_LOGS,
        ],
      },
      {
        id: "seo_templates",
        icon: ViewListIcon,
        ...links.value.SEO_TEMPLATES,
      },
      {
        id: "admins",
        text: "Администраторы",
        icon: SettingsIcon,
        children: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
      },
    ];

    return {
      sidebarMenuList,
    };
  },
});
