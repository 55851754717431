import { Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";
import { FieldConfigUnion, RepeaterField } from "@tager/admin-dynamic-field";

import { createGalleryFileInputValue } from "@/utils/common";
import { TYPE_OPTIONS } from "@/modules/cars/partner/constants";

import {
  PartnerCarCreateOrUpdatePayload,
  PartnerCarFullInterface,
  PartnerCarParams,
} from "../typings";

export enum Currency {
  Byn = "BYN",
  Usd = "USD",
}

export const CURRENCY_OPTIONS: Array<OptionType<Currency>> = [
  { label: "Белорусский рубль", value: Currency.Byn },
  { label: "Доллар США", value: Currency.Usd },
];

export const PRICING_CHINA_CONTAINER_OPTIONS: Array<OptionType<number>> = [
  { label: "2-х местный контейнер", value: 2 },
  { label: "3-х местный контейнер", value: 3 },
];

export enum PriceStrategy {
  Fixed = "FIXED",
  China = "CHINA",
}

export const PRICE_STRATEGY_OPTIONS: Array<OptionType<PriceStrategy>> = [
  {
    value: PriceStrategy.Fixed,
    label: "Точная цена",
  },
  {
    value: PriceStrategy.China,
    label: "Китай",
  },
];

type FormValuesChinaPricing = {
  priceChinaRecommendedPrice: Nullable<number>;
  priceChinaDiscountPrice: Nullable<number>;
  priceChinaTradeInPrice: Nullable<number>;
  priceChinaProfit: Nullable<number>;
  priceChinaContainer: OptionType<number>;
};

type FormValuesFixedPricing = {
  priceFixedCurrency: Nullable<OptionType<Currency>>;
  priceFixedPrice: Nullable<number>;
  priceFixedDiscountPrice: Nullable<number>;
  priceFixedTradeInPrice: Nullable<number>;
};

export type FormValues = {
  hidden: boolean;
  type: Nullable<OptionType>;
  status: Nullable<OptionType>;
  stockStatus: Nullable<OptionType>;
  brand: Nullable<OptionType<number>>;
  model: Nullable<OptionType<number>>;
  generation: Nullable<OptionType<number>>;
  complectationName: Nullable<string>;
  vin: Nullable<string>;
  year: Nullable<number>;
  mileage: Nullable<number>;
  onWarranty: boolean;
  warrantyHint: string | null;
  availableTestDrive: boolean;
  availableOnlineSale: boolean;

  videoUrl: Nullable<string>;
  photos: Array<Nullable<SingleFileInputValueType>>;

  color: Nullable<OptionType>;
  colorInterior: Nullable<OptionType>;

  fuel: Nullable<OptionType>;
  engineVolume: Nullable<number>;
  enginePower: Nullable<number>;
  engine2Power: Nullable<number>;
  engineMaxTorque: Nullable<number>;
  engine2MaxTorque: Nullable<number>;
  distanceLimit: Nullable<number>;

  battery: Nullable<OptionType>;
  batteryCapacity: Nullable<number>;
  batteryQuantity: Nullable<number>;
  wltpExpense: Nullable<number>;
  chargeAcTime: Nullable<number>;
  chargeDcTime: Nullable<number>;
  time0To100: Nullable<number>;
  maxSpeed: Nullable<number>;

  gearbox: Nullable<OptionType>;
  wheel: Nullable<OptionType>;
  weight: Nullable<number>;
  weightFull: Nullable<number>;
  warranty: Nullable<string>;

  body: Nullable<OptionType>;
  length: number | null;
  width: number | null;
  height: number | null;
  clearance: number | null;
  rotateDiameter: number | null;
  trunkVolume: number | null;
  maxTrunkVolume: number | null;
  wheelBase: number | null;
  trackFront: number | null;
  trackBack: number | null;
  doorsCount: number | null;
  seatsCount: number | null;

  description: Nullable<string>;
  additional: Nullable<string>;

  priceHidden: boolean;
  priceStrategy: OptionType<PriceStrategy>;
  gift: Nullable<OptionType<number>>;
} & FormValuesFixedPricing &
  FormValuesChinaPricing;

function parsePriceFormValues(
  car: PartnerCarFullInterface
): Record<string, unknown> {
  const priceStrategy =
    PRICE_STRATEGY_OPTIONS.find(
      (item) => item.value === car.pricing.strategy
    ) || PRICE_STRATEGY_OPTIONS[0];

  let pricingParamsObject = {};
  if (priceStrategy.value === PriceStrategy.Fixed) {
    pricingParamsObject = {
      priceFixedPrice: car.pricing.payload.price ?? null,
      priceFixedDiscountPrice: car.pricing.payload.discountPrice ?? null,
      priceFixedTradeInPrice: car.pricing.payload.tradeInPrice ?? null,
      priceFixedCurrency:
        CURRENCY_OPTIONS.find(
          (item) => item.value === car.pricing.payload.currency
        ) ?? null,
    };
  } else {
    pricingParamsObject = {
      priceChinaDiscountPrice: car.pricing.payload.discountPrice ?? null,
      priceChinaRecommendedPrice: car.pricing.payload.recommendedPrice ?? null,
      priceChinaTradeInPrice: car.pricing.payload.tradeInPrice ?? null,
      priceChinaProfit: car.pricing.payload.profit ?? null,
      priceChinaContainer:
        PRICING_CHINA_CONTAINER_OPTIONS.find(
          (item) => item.value === car.pricing.payload.container
        ) || PRICING_CHINA_CONTAINER_OPTIONS[0],
    };
  }

  return {
    priceHidden: car.pricing.priceHidden,
    priceStrategy: priceStrategy,
    ...pricingParamsObject,
  };
}

export function getAmpCarFormValue(
  partnerCar: Nullable<PartnerCarFullInterface>,
  apiParams: Nullable<PartnerCarParams>
): FormValues {
  if (!partnerCar) {
    return {
      hidden: false,
      status: null,
      stockStatus: null,
      type: null,
      brand: null,
      model: null,
      generation: null,
      complectationName: null,
      vin: null,
      year: null,
      mileage: null,
      onWarranty: false,
      availableTestDrive: false,
      availableOnlineSale: false,

      videoUrl: null,
      photos: [],

      color: null,
      colorInterior: null,

      fuel: null,
      engineVolume: null,
      enginePower: null,
      engine2Power: null,
      engineMaxTorque: null,
      engine2MaxTorque: null,
      distanceLimit: null,
      battery: null,
      batteryCapacity: null,
      batteryQuantity: null,
      wltpExpense: null,
      chargeAcTime: null,
      chargeDcTime: null,

      time0To100: null,
      maxSpeed: null,

      gearbox: null,
      wheel: null,
      weight: null,
      weightFull: null,
      warranty: null,
      warrantyHint: null,

      body: null,
      length: null,
      width: null,
      height: null,
      clearance: null,
      trunkVolume: null,
      maxTrunkVolume: null,
      wheelBase: null,
      rotateDiameter: null,
      trackFront: null,
      trackBack: null,
      doorsCount: null,
      seatsCount: null,

      description: null,
      additional: null,

      priceStrategy: PRICE_STRATEGY_OPTIONS[0],
      priceHidden: false,

      priceFixedCurrency: CURRENCY_OPTIONS[0],
      priceFixedPrice: null,
      priceFixedDiscountPrice: null,
      priceFixedTradeInPrice: null,

      priceChinaDiscountPrice: null,
      priceChinaRecommendedPrice: null,
      priceChinaTradeInPrice: null,
      priceChinaProfit: null,
      priceChinaContainer: PRICING_CHINA_CONTAINER_OPTIONS[0],

      gift: null,
    };
  }

  const brandModel = apiParams?.brands
    ? apiParams.brands.find((item) => item.id === partnerCar.brand)
    : null;

  const modelModel = brandModel
    ? brandModel.models.find((item) => item.id === partnerCar.model)
    : null;

  const generationModel = modelModel
    ? modelModel.generations.find((item) => item.id === partnerCar.generation)
    : null;

  return {
    hidden: partnerCar.hidden,

    status: apiParams?.statuses
      ? apiParams.statuses.find((item) => item.value === partnerCar.status) ||
        null
      : null,
    stockStatus: apiParams?.stockStatuses
      ? apiParams.stockStatuses.find(
          (item) => item.value === partnerCar.stockStatus
        ) || null
      : null,
    type: TYPE_OPTIONS.find((item) => item.value === partnerCar.type) || null,
    brand: brandModel
      ? {
          value: brandModel.id,
          label: brandModel.name,
        }
      : null,
    model: modelModel
      ? {
          value: modelModel.id,
          label: modelModel.name,
        }
      : null,
    generation: generationModel
      ? {
          value: generationModel.id,
          label: generationModel.name,
        }
      : null,

    complectationName: partnerCar.complectationName,

    vin: partnerCar.vin,
    year: partnerCar.year,

    mileage: partnerCar.mileage,
    onWarranty: partnerCar.onWarranty,
    warrantyHint: partnerCar.warrantyHint,
    availableTestDrive: partnerCar.availableTestDrive,
    availableOnlineSale: partnerCar.availableOnlineSale,
    videoUrl: partnerCar.videoUrl,
    photos: partnerCar.photos
      ? partnerCar.photos.map((image) => createGalleryFileInputValue(image))
      : [],

    color: apiParams?.colors
      ? apiParams.colors.find((item) => item.value === partnerCar.color) || null
      : null,
    colorInterior: apiParams?.colors
      ? apiParams.colors.find(
          (item) => item.value === partnerCar.colorInterior
        ) || null
      : null,

    fuel: apiParams?.fuelTypes
      ? apiParams.fuelTypes.find((item) => item.value === partnerCar.fuel) ||
        null
      : null,
    engineVolume: partnerCar.engineVolume,
    enginePower: partnerCar.enginePower,
    engine2Power: partnerCar.engine2Power,

    distanceLimit: partnerCar.distanceLimit,
    battery: apiParams?.batteries
      ? apiParams.batteries.find((item) => item.value === partnerCar.battery) ||
        null
      : null,
    batteryCapacity: partnerCar.batteryCapacity,
    batteryQuantity: partnerCar.batteryQuantity,
    wltpExpense: partnerCar.wltpExpense,
    chargeAcTime: partnerCar.chargeAcTime,
    chargeDcTime: partnerCar.chargeDcTime,

    engineMaxTorque: partnerCar.engineMaxTorque,
    engine2MaxTorque: partnerCar.engine2MaxTorque,
    maxSpeed: partnerCar.maxSpeed,
    time0To100: partnerCar.time0To100,

    gearbox: apiParams?.gearboxes
      ? apiParams.gearboxes.find((item) => item.value === partnerCar.gearbox) ||
        null
      : null,
    wheel: apiParams?.wheels
      ? apiParams.wheels.find((item) => item.value === partnerCar.wheel) || null
      : null,
    weight: partnerCar.weight || null,
    weightFull: partnerCar.weightFull || null,
    warranty: partnerCar.warranty || null,

    body: apiParams?.bodyTypes
      ? apiParams.bodyTypes.find((item) => item.value === partnerCar.body) ||
        null
      : null,
    length: partnerCar.length || null,
    width: partnerCar.width || null,
    height: partnerCar.height || null,
    clearance: partnerCar.clearance || null,
    trunkVolume: partnerCar.trunkVolume || null,
    maxTrunkVolume: partnerCar.maxTrunkVolume || null,
    wheelBase: partnerCar.wheelBase || null,
    rotateDiameter: partnerCar.rotateDiameter || null,
    trackFront: partnerCar.trackFront || null,
    trackBack: partnerCar.trackBack || null,
    doorsCount: partnerCar.doorsCount || null,
    seatsCount: partnerCar.seatsCount || null,

    description: partnerCar.description,
    additional: partnerCar.additional,

    priceStrategy: PRICE_STRATEGY_OPTIONS[0],
    priceHidden: false,

    priceFixedCurrency: null,
    priceFixedDiscountPrice: null,
    priceFixedTradeInPrice: null,
    priceFixedPrice: null,

    priceChinaDiscountPrice: null,
    priceChinaRecommendedPrice: null,
    priceChinaTradeInPrice: null,
    priceChinaProfit: null,
    priceChinaContainer: PRICING_CHINA_CONTAINER_OPTIONS[0],

    gift: partnerCar.gift
      ? {
          value: partnerCar.gift.id,
          label: partnerCar.gift.name,
        }
      : null,

    ...parsePriceFormValues(partnerCar),
  };
}

function getPricePayload(values: FormValues): Record<string, unknown> {
  if (values.priceStrategy.value === PriceStrategy.Fixed) {
    return {
      currency: values.priceFixedCurrency?.value || Currency.Byn,
      price: values.priceFixedPrice,
      discountPrice: values.priceFixedDiscountPrice,
      tradeInPrice: values.priceFixedTradeInPrice,
    };
  } else if (values.priceStrategy.value === PriceStrategy.China) {
    return {
      recommendedPrice: values.priceChinaRecommendedPrice,
      discountPrice: values.priceChinaDiscountPrice,
      tradeInPrice: values.priceChinaTradeInPrice,
      profit: values.priceChinaProfit,
      container: values.priceChinaContainer.value,
    };
  } else {
    return {};
  }
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues,
  factoryFieldValue: RepeaterField
): PartnerCarCreateOrUpdatePayload {
  return {
    hidden: values.hidden,
    type: values.type?.value || null,
    status: values.status?.value || null,
    stockStatus: values.stockStatus?.value || null,
    brand: values.brand?.value || null,
    model: values.model?.value || null,
    generation: values.generation?.value || null,
    complectationName: values.complectationName,
    vin: values.vin,
    year: values.year ? +values.year : null,
    mileage: values.mileage ? +values.mileage : null,

    onWarranty: values.onWarranty,
    warrantyHint: values.warrantyHint,
    availableTestDrive: values.availableTestDrive,
    availableOnlineSale: values.availableOnlineSale,
    photos: values.photos
      ? values.photos.map((item) => item?.file.id as string)
      : [],
    videoUrl: values.videoUrl,

    color: values.color?.value || null,
    colorInterior: values.colorInterior?.value || null,

    fuel: values.fuel?.value || null,
    engineVolume: values.engineVolume ? +values.engineVolume : null,
    enginePower: values.enginePower ? +values.enginePower : null,
    engine2Power: values.engine2Power ? +values.engine2Power : null,
    distanceLimit: values.distanceLimit,
    battery: values.battery?.value || null,
    batteryCapacity: values.batteryCapacity,
    batteryQuantity: values.batteryQuantity,
    wltpExpense: values.wltpExpense,
    chargeAcTime: values.chargeAcTime,
    chargeDcTime: values.chargeDcTime,
    maxSpeed: values.maxSpeed,
    time0to100: values.time0To100,
    engineMaxTorque: values.engineMaxTorque,
    engine2MaxTorque: values.engine2MaxTorque,

    gearbox: values.gearbox?.value || null,
    wheel: values.wheel?.value || null,
    weight: values.weight || null,
    weightFull: values.weightFull || null,
    warranty: values.warranty || null,

    body: values.body?.value || null,
    length: values.length || null,
    width: values.width || null,
    height: values.height || null,
    clearance: values.clearance || null,
    trunkVolume: values.trunkVolume || null,
    maxTrunkVolume: values.maxTrunkVolume || null,
    wheelBase: values.wheelBase || null,
    rotateDiameter: values.rotateDiameter || null,
    trackFront: values.trackFront || null,
    trackBack: values.trackBack || null,
    doorsCount: values.doorsCount || null,
    seatsCount: values.seatsCount || null,

    equipment: factoryFieldValue.value.map((item) => {
      return {
        section: String(item.value[0].value) || "",
        options: (item.value[1].value as any[]).map((item) => {
          return {
            name: String(item.value[0].value),
            value: String(item.value[1].value) || null,
          };
        }),
      };
    }),

    description: values.description,
    additional: values.additional,

    priceHidden: values.priceHidden,
    priceStrategy: values.priceStrategy.value,
    pricePayload: getPricePayload(values),

    gift: values.gift?.value || null,
  };
}

export const factoryOptionsFieldConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Заводские опции",
  meta: {
    view: "TABLE",
    hideCount: true,
  },
  fields: [
    {
      name: "section",
      type: "STRING",
      label: "Категория",
      width: "250px",
    },
    {
      name: "options",
      type: "REPEATER",
      label: "Опции",
      meta: {
        view: "TABLE",
        hideCount: true,
        noToggle: true,
      },
      fields: [
        {
          name: "name",
          type: "STRING",
          label: "Название",
        },
        {
          name: "value",
          type: "STRING",
          label: "Значение",
          width: "250px",
        },
      ],
    },
  ],
};
