import { createWebHistory, type RouteRecordRaw } from "vue-router";

import { createRouter } from "@tager/admin-layout";
import {
  ADMIN_FORM_ROUTE,
  ADMIN_LIST_ROUTE,
  ROLE_FORM_ROUTE,
  ROLE_LIST_ROUTE,
} from "@tager/admin-administrators";
import {
  SETTINGS_ITEM_FORM_ROUTE,
  SETTINGS_ITEM_LIST_ROUTE,
} from "@tager/admin-settings";
import {
  PAGE_COMMANDS_ROUTE,
  PAGE_CRON_LOGS_ROUTE,
  PAGE_COMMANDS_LOGS_ROUTE,
  PAGE_COMMANDS_LOG_DETAILS_ROUTE,
  PAGE_CRON_LOG_DETAILS_ROUTE,
  PAGE_COMMANDS_DETAILS_ROUTE,
} from "@tager/admin-cron";
import { SEO_TEMPLATES_ROUTE } from "@tager/admin-seo";

import Home from "@/views/Home.vue";
import { AMP_CARS_LIST_ROUTE, AMP_CARS_FORM_ROUTE } from "@/modules/cars/amp";
import { NEW_CARS_LIST_ROUTE, NEW_CARS_FORM_ROUTE } from "@/modules/cars/new";
import {
  GIFTS_CREATE_FORM_ROUTE,
  GIFTS_LIST_ROUTE,
  GIFTS_UPDATE_FORM_ROUTE,
} from "@/modules/gifts";
import { CARS_SYNC_SESSIONS_LIST_ROUTE } from "@/modules/cars/sync-sessions";
import {
  PARTNER_CARS_FORM_ROUTE,
  PARTNER_CARS_LIST_ROUTE,
} from "@/modules/cars/partner";
import { COLORS_FORM_ROUTE, COLORS_LIST_ROUTE } from "@/modules/colors";

export const HOME_ROUTE: RouteRecordRaw = {
  path: "/",
  component: Home,
  name: "Home",
  meta: {
    getBreadcrumbs: () => [{ url: "/", text: "Главная" }],
  },
};

const routes = [
  HOME_ROUTE,
  SEO_TEMPLATES_ROUTE,

  ADMIN_LIST_ROUTE,
  ADMIN_FORM_ROUTE,
  ROLE_LIST_ROUTE,
  ROLE_FORM_ROUTE,
  PAGE_COMMANDS_ROUTE,
  PAGE_CRON_LOGS_ROUTE,
  PAGE_COMMANDS_LOGS_ROUTE,
  PAGE_COMMANDS_LOG_DETAILS_ROUTE,
  PAGE_CRON_LOG_DETAILS_ROUTE,
  PAGE_COMMANDS_DETAILS_ROUTE,
  SETTINGS_ITEM_FORM_ROUTE,
  SETTINGS_ITEM_LIST_ROUTE,

  NEW_CARS_LIST_ROUTE,
  NEW_CARS_FORM_ROUTE,
  AMP_CARS_LIST_ROUTE,
  AMP_CARS_FORM_ROUTE,

  PARTNER_CARS_FORM_ROUTE,
  PARTNER_CARS_LIST_ROUTE,

  COLORS_FORM_ROUTE,
  COLORS_LIST_ROUTE,

  CARS_SYNC_SESSIONS_LIST_ROUTE,

  GIFTS_CREATE_FORM_ROUTE,
  GIFTS_LIST_ROUTE,
  GIFTS_UPDATE_FORM_ROUTE,
];

export const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH || "/"),
  routes,
});
